import { Task } from "../models/task.model";

const mocks: Task[] =  [
    {
      ids: ["code-12"],
      assignee: 'Didier François',
      assigneeBadgeDisplayName: 'DF',
      title: "Blocked Fund 02 Onboarding",
      text: "Missing POA",
      date: new Date(),
      isUnread: true,
      serviceName: 'SO',
      serviceFullName: 'Securities Onboarding',
      serviceIcon: 'person',
      serviceIconClasses: 'bg-yellow-50'
    },
    {
      ids: ["code-34"],
      title: "UninstructedCA #4269JKL",
      text: "12 hours left",
      date: new Date('2022-09-15'),
      serviceName: 'CS',
      serviceFullName: 'Custody Solutions',
      serviceIcon: 'description',
      serviceIconClasses: 'bg-blue-50'
    },
    {
      ids: ["code-56"],
      assignee: 'Thierry Laporte',
      assigneeBadgeDisplayName: 'TL',
      title: "4 orders in past cut-off",
      text: "For a total of 3.5 M€",
      date: new Date('2022-09-15'),
      serviceName: 'CS',
      serviceFullName: 'Custody Solutions',
      serviceIcon: 'verified_user',
      serviceIconClasses: 'bg-green-50'
    },
    {
      ids: ["code-78"],
      title: "Expired document",
      assignee: 'Roger Lecomte',
      assigneeBadgeDisplayName: 'RL',
      text: "POA #444552222",
      date: new Date('2022-09-15'),
      serviceName: 'SO',
      serviceFullName: 'Securities Onboarding',
      serviceIcon: 'payments',
      serviceIconClasses: 'bg-turquoise-50'
    },
    {
      ids: ["code-90"],
      title: "You have 4 orders in draft mode",
      text: '',
      date: new Date('2022-09-15'),
      serviceName: 'CS',
      serviceFullName: 'Custody Solutions',
      serviceIcon: 'security',
      serviceIconClasses: 'bg-purple-50'
    },
  ];
  
  const getTasksMock = (assetOwner: boolean): Task[] => {
      if (assetOwner) {
          // Remove all “SO” notifications for action (expired document / blocked fund 02 onboarding)
          // As this client is an Asset owner (he does not launch funds) and replace them by “SI” notifications
          mocks[0] = {
              ids: ["code-12"],
              assigneeBadgeDisplayName: 'GB',
              title: "5 invoice(s) awaiting validation",
              text: "Invoicing",
              date: new Date(),
              isUnread: true,
              serviceName: 'SI',
              serviceFullName: 'Securities Invoicing'
          };
          mocks[3] = {
              ids: ["code-78"],
              assigneeBadgeDisplayName: 'DF',
              title: "2 invoice(s) to be settled",
              text: "Invoicing",
              date: new Date('2022-09-15'),
              serviceName: 'SI',
              serviceFullName: 'Securities Invoicing'
          };
      }
      return mocks.map(m => ({ ...m }));
  }
  export default getTasksMock;