import React, { useEffect, useState } from "react";
import { widgetize, WidgetPropsMapping, WithCss } from "@sg-widgets/react-core";
import { Analytics, AnalyticCallback, List, NoDataFiller, ViewSelector } from "@sgss-ttd-widgets/components";

import * as listStyles from "@sgss-ttd-widgets/components/styles/list.less";
import * as widgetStyles from "./ttd-flow-monitoring.less";
import { Task } from "../models/task.model";
import getTasksMock from "../mocks/tasks.mock";
import ActionTemplate from "./components/action-template";
import StatusTemplate from "./components/status-template";
import TitleTemplate from "./components/title-template";
import i18n from "./i18n";
import { useSgWidgetTranslation } from "../common/use-sg-widgets-translation";
import ItemTextTemplate from "./components/item-text-template";
import ItemInfoTemplate from "./components/item-info-template";
import { getMergedStyles } from "../utils";

interface Props {
  items: Task[];
  hasUnauthorizedAccess: boolean;
  hasDataFetchingError: boolean;
  assetOwner: boolean;
  isLoading: boolean;
  isComingSoon: boolean;
  pages: number;
  pageChange: (page: number) => void;
  itemClick: (data: { item: Task }) => void;
  assignClick: (data: { item: Task }) => void;
  assignToMeClick: (data: { item: Task }) => void;
  unassignClick: (data: { item: Task }) => void;
  seeAllClick: () => void;
  helpClick: () => void;
  analyticCallback: AnalyticCallback;
  retryClick: () => void;
}

const FlowMonitoring: React.FC<Props> = ({
  items,
  hasUnauthorizedAccess,
  hasDataFetchingError,
  assetOwner,
  isLoading,
  isComingSoon,
  pages,
  pageChange,
  itemClick,
  assignClick,
  assignToMeClick,
  unassignClick,
  seeAllClick,
  helpClick,
  analyticCallback,
  retryClick
}: Props) => {
  const itemIconPropertyPath = 'serviceIcon';
  const itemIconClassesPropertyPath = 'serviceIconClasses';
  const itemIconTooltipPropertyPath = 'serviceFullName';
  const itemTitlePropertyPath = 'title';
  const [itemsState, setItemsState] = useState(items);
  const [hasUnauthorizedAccessState, setHasUnauthorizedAccessState] = useState(hasUnauthorizedAccess);
  const [hasDataFetchingErrorState, setHasDataFetchingErrorState] = useState(hasDataFetchingError);
  const [assetOwnerState, setAssetOwnerState] = useState(assetOwner);
  const [hasMockDataLoadedState, setHasMockDataLoadedState] = useState(false);
  const { currentLanguage, t: translate } = useSgWidgetTranslation(i18n);

  const createPayloadSeeAllClick = Analytics.payloadFactory('buttonClick', 'Click to see all tasks');
  const createPayloadItemClick = Analytics.payloadFactory('buttonClick', 'Click on a task');
  const createPayloadAssignClick = Analytics.payloadFactory('buttonClick', 'Click to assign a task');
  const createPayloadAssignToMeClick = Analytics.payloadFactory('buttonClick', 'Click to assign a task to me');
  const createPayloadUnassignClick = Analytics.payloadFactory('buttonClick', 'Click to unassign a task');
  const createPayloadHelpClick = Analytics.payloadFactory('buttonClick', 'Click on help');

  useEffect(() => {
    if (items !== itemsState) {
      setItemsState(items);
    }
  }, [items]);

  useEffect(() => {
    if (hasUnauthorizedAccess !== hasUnauthorizedAccessState) {
      setHasUnauthorizedAccessState(hasUnauthorizedAccess);
    }
  }, [hasUnauthorizedAccess]);

  useEffect(() => {
    if (hasDataFetchingError !== hasDataFetchingErrorState) {
      setHasDataFetchingErrorState(hasDataFetchingError);
    }
  }, [hasDataFetchingError]);

  useEffect(() => {
    if (assetOwner !== assetOwnerState) {
      setAssetOwnerState(assetOwner);
    }
  }, [assetOwner]);

  const NoDataActionTemplate = (): JSX.Element => {
    const demoBtn = <button
      className="btn btn-discreet-primary btn-lg btn-icon-start"
      onClick={() => {
        setHasUnauthorizedAccessState(false);
        setHasDataFetchingErrorState(false);
        setItemsState(getTasksMock(assetOwnerState));
        setHasMockDataLoadedState(true);
      }}>
      <i className="icon">visibility</i> {translate('ttdFlowMonitoring.demoBtn')}
    </button>;
    
    const retryAndHistoricBtn = <div>
      <button
        className="btn btn-discreet-primary btn-lg btn-icon-start"
        onClick={() => {
          // const tasks = getTasksMock(false);
          // setItemsState(tasks);
          retryClick();
        }}>
        <i className="icon">refresh</i> {translate('ttdFlowMonitoring.retryBtn')}
      </button>
    </div>;
    
    return isComingSoon
      ? demoBtn
      : retryAndHistoricBtn
  };

  const actionsTemplate = <NoDataActionTemplate />;
  const content = 
      <ViewSelector
        itemsCount={1}
        hasUnauthorizedAccess={hasUnauthorizedAccessState}
        hasDataFetchingError={false}
        actionsTemplate={actionsTemplate}
        isComingSoon={isComingSoon}
        language={currentLanguage}
        isLoading={isLoading}
        unauthorizedAccessFiller={
          <NoDataFiller title={translate('ttdFlowMonitoring.viewSelector.unauthorizedAccess.title')} icon="block">
            {translate('ttdFlowMonitoring.viewSelector.unauthorizedAccess.message')}
          </NoDataFiller>
        }
        noDataFiller={
          <NoDataFiller title={translate('ttdFlowMonitoring.viewSelector.noData.title')} icon="widgets" actionsTemplate={actionsTemplate}>
            {translate('ttdFlowMonitoring.viewSelector.noData.message')}
          </NoDataFiller>
        }>
          <List<Task> title={translate('ttdFlowMonitoring.title')}
            titleTemplate={ <TitleTemplate /> }
            items={itemsState}
            itemTitlePropertyPath={itemTitlePropertyPath}
            itemTextTemplate={<ItemTextTemplate />}
            itemIconPropertyPath={itemIconPropertyPath}
            itemIconClassesPropertyPath={itemIconClassesPropertyPath}
            itemIconTooltipPropertyPath={itemIconTooltipPropertyPath}
            itemTextPropertyPath="text"
            itemHighlightPredicate={item => !!item.isUnread}
            actionsTemplate={
              <ActionTemplate helpClick={() => { helpClick(); analyticCallback(createPayloadHelpClick()); }}/>}
            itemStatusTemplate={<StatusTemplate/>}
            itemInfoTemplate={
              <ItemInfoTemplate 
                assignClick={(data: {item: Task}) => { assignClick(data); analyticCallback(createPayloadAssignClick(data.item.title)); }}
                assignToMeClick={(data: {item: Task}) => { assignToMeClick(data); analyticCallback(createPayloadAssignToMeClick(data.item.title)); }}
                unassignClick={(data: {item: Task}) => { unassignClick(data); analyticCallback(createPayloadUnassignClick(data.item.title)); }} />}
            noDataFiller={
              <NoDataFiller title={translate('ttdFlowMonitoring.viewSelector.noData.title')} icon="widgets" actionsTemplate={actionsTemplate}>
                {translate('ttdFlowMonitoring.viewSelector.noData.message')}
              </NoDataFiller>
            }
            dataFetchingErrorFiller={
              <NoDataFiller title={translate('ttdFlowMonitoring.viewSelector.technicalIssue.title')} icon="error_outline" actionsTemplate={actionsTemplate}>
                {translate('ttdFlowMonitoring.viewSelector.technicalIssue.message')}
              </NoDataFiller>
            }
            onClick={ data => { itemClick(data); analyticCallback(createPayloadItemClick(data.item.title)); }}
            onTitleClick={() => { seeAllClick(); analyticCallback(createPayloadSeeAllClick()); }}
            pages={pages}
            onPageChange={(page) => pageChange(page)}
            isLoading={isLoading}
            hasDataFetchingError={hasDataFetchingErrorState}
          />
        </ViewSelector>
  
  const styles = getMergedStyles(listStyles, widgetStyles);
  return (
    <WithCss styles={styles}>
      <div className='wrapper d-flex bg-lvl1'>
        { content }
      </div>
    </WithCss>
  );
};

widgetize("ttd-flow-monitoring", FlowMonitoring, {
  items: WidgetPropsMapping.asObject({
    description: "The items",
  }),
  hasUnauthorizedAccess: WidgetPropsMapping.asObject({
    description: "Indicates wether the access in authorized or not",
  }),
  hasDataFetchingError: WidgetPropsMapping.asObject({
    description: "Indicates if there was an error when trying to fetch the data",
  }),
  assetOwner: WidgetPropsMapping.asObject({
    description: "Indicates if the user is an asset owner, to adjust mocked data",
  }),
  isLoading: WidgetPropsMapping.asObject({
    description: "Indicates if there is a load operation pending",
  }),
  isComingSoon: WidgetPropsMapping.asObject({
    description: "Indicates if the widget is in a WIP state",
  }),
  itemClick: WidgetPropsMapping.asEventEmitter("item-click", {
    description: "Event occurring when an item is clicked with data containing the item payload",
  }),
  pages: WidgetPropsMapping.asNumber({
    description: "Indicates the number of pages to display in the pager",
  }),
  pageChange: WidgetPropsMapping.asEventEmitter("page-change", {
    description: "Event occurring when a page is selected",
  }),
  assignClick: WidgetPropsMapping.asEventEmitter("assign-click", {
    description: "Event occurring when an assign button is clicked",
  }),
  assignToMeClick: WidgetPropsMapping.asEventEmitter("assign-to-me-click", {
    description: "Event occurring when an assign to me button is clicked",
  }),
  unassignClick: WidgetPropsMapping.asEventEmitter("unassign-click", {
    description: "Event occurring when an unassign button is clicked",
  }),
  seeAllClick: WidgetPropsMapping.asEventEmitter("see-all-click", {
    description: "Event occurring when the see all button is clicked",
  }),
  helpClick: WidgetPropsMapping.asEventEmitter("help-click", {
    description: "Event occurring when the help button is clicked",
  }),
  analyticCallback: WidgetPropsMapping.asEventEmitter("analytic-callback", {
    description: "Event occurring when an event should be logged to the analytic module for monitoring",
  }),
  retryClick: WidgetPropsMapping.asEventEmitter("retry-click", {
    description: "Event occurring when retry button is clicked",
  })
});
