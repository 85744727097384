import { ListItemContext, ListItemContextModel } from "@sgss-ttd-widgets/components";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import { WithT } from "i18next";

import { Task } from "../../models/task.model";

const ItemTextTemplate: React.FC<WithT<any>> = ({ t }) => {
    const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
    const item: Task = itemContext?.item;
    const { date } = item;
    const formatedDate =  t('common.itemDate', { date: date });

    return (
        <>{`${formatedDate} · ${item.serviceFullName}`}</>
    );
}

  export default withTranslation()(ItemTextTemplate);