import React, { useContext } from "react";
import { ListItemContext, ListItemContextModel, Medal, TooltipTrigger } from "@sgss-ttd-widgets/components";
import { Task } from "../../models/task.model";

const StatusTemplate = (): JSX.Element => {
  const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
  const item: Task = itemContext?.item;
  const serviceMedal = <Medal>{ item.serviceName }</Medal>;
  return (
    itemContext?.item?.serviceFullName 
    ? <TooltipTrigger placement="left" content={itemContext?.item?.serviceFullName}>
        <span>{serviceMedal}</span>
      </TooltipTrigger>
    : serviceMedal
)
};

export default StatusTemplate;