import React, { useContext } from "react";
import { Dropdown, DropdownItem, ListItemContext, ListItemContextModel, TooltipTrigger } from "@sgss-ttd-widgets/components";
import { Task } from "../../models/task.model";
import { withTranslation } from "react-i18next";
import { WithT } from "i18next";

type Props = {
  assignClick?: (data: { item: Task }) => void,
  assignToMeClick?: (data: { item: Task }) => void,
  unassignClick?: (data: { item: Task }) => void,
} & WithT<any>;

const ItemActionTemplate: React.FC<Props> = ({
   assignClick,
   assignToMeClick,
   unassignClick,
   t
}: Props) => {
  const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
  const item: Task = itemContext?.item;

  const generateDropdown = () => (
    <Dropdown placement="bottom-start"> {
        item.canAssign
          ? <DropdownItem onClick={() => assignClick && assignClick({ item }) }>
              <i className="icon icon-sm text-secondary lh-1 me-3">assignment</i>
              <span>{t('ttdFlowMonitoring.assign')}</span>
            </DropdownItem>
          : <></>
      } { 
      !item.assignedToMe 
        ? <DropdownItem onClick={() => assignToMeClick && assignToMeClick({ item }) }>
            <i className="icon icon-sm text-secondary lh-1 me-3">assignment_ind</i>
            <span>{t('ttdFlowMonitoring.assignToMe')}</span>
          </DropdownItem>
        : <></>
      } {
      item.assignee
        ? <DropdownItem onClick={() => unassignClick && unassignClick({ item }) }>
            <i className="icon icon-sm text-secondary lh-1 me-3">person_off</i>
            <span>{t('ttdFlowMonitoring.unassign')}</span>
          </DropdownItem>
        : <></>
      }
    </Dropdown>
  );

  return (
    item.assigneeBadgeDisplayName ? 
    <>
      <TooltipTrigger placement="left" content={item.assignee 
        ? (<>
            <span>{t('ttdFlowMonitoring.assignedTo')}&nbsp;</span>
            <br/>
            <span>{item.assignee}</span>
          </>) 
        : <></>}>
        <div
          className="rounded-circle fs-12 d-flex align-items-center justify-content-center text-secondary fw-semibold bg-manatee me-12px"
          style={{ width: '32px', height: '32px' }}>
            <div className="fs-12 fw-medium text-primary-alt">
              {item.assigneeBadgeDisplayName}
            </div>
        </div>
      </TooltipTrigger>
      { generateDropdown() }
    </>
    : generateDropdown()
  );
};

export default withTranslation()(ItemActionTemplate);