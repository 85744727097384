import { useWidgetBusData } from "@sg-widgets/react-core";
import { i18n } from 'i18next';
import { useTranslation } from "react-i18next";

export function useSgWidgetTranslation(i18n: i18n): any {
    const currentLanguage: string = useWidgetBusData('global.language')!;
    if (i18n.language !== currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    }
    return { currentLanguage, ...useTranslation() };
}