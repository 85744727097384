import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { format, isToday } from 'date-fns';
import { fr } from "date-fns/locale";

const resources = {
  en: {
    translation: {
      common: {
        itemDate: "{{ date, itemDate }}"
      },
      ttdFlowMonitoring: {
        viewSelector: {
          noData: {
            title: 'No tasks',
            message: 'You do not have any task.'
          },
          technicalIssue: {
            title: 'Technical issue',
            message: 'A problem occurred while loading this content.'
          },
          unauthorizedAccess: {
            title: 'Unauthorized access',
            message: 'You cannot access tasks with the "Navigate as" option.'
          }
        },
        title: 'To do',
        subtitle: '5 latest pending tasks',
        retryBtn: 'Retry',
        demoBtn: 'Display with demo data',
        seeAllBtn: 'See all',
        historicBtn: 'View history',
        demoBadge: 'Demo data',
        assign: 'Assign',
        unassign: 'Unassign',
        assignToMe: 'Assign to me',
        assignedTo: 'Assigned to'
      }
    }
  },
  fr: {
    translation: {
      common: {
        itemDate: "{{ date, itemDate }}"
      },
      ttdFlowMonitoring: {
        viewSelector: {
          noData: {
            title: 'Pas de tâche',
            message: 'Vous n\'avez aucune tâche.'
          },
          technicalIssue: {
            title: 'Problème technique',
            message: 'Un problème est survenu pendant le chargement de ce contenu.'
          },
          unauthorizedAccess: {
            title: 'Accès non autorisé',
            message: 'Vous ne pouvez pas accéder aux tâches avec l\'option "Navigate as".'
          }
        },
        title: 'Tâches',
        subtitle: '5 dernières tâches en cours',
        retryBtn: 'Réessayer',
        demoBtn: 'Afficher en démo',
        seeAllBtn: 'Voir tout',
        historicBtn: 'Voir l\'historique',
        demoBadge: 'Données de demo',
        assign: 'Assigner',
        unassign: 'Désassigner',
        assignToMe: 'Assigner à moi',
        assignedTo: 'Assigné à'
      }
    }
  },
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
      format: (value, fmt, lng) => {
        switch (fmt) {
          case 'itemDate':
            if (isToday(value)) {
              return lng === 'fr' 
                ? format(value, 'p', { locale: fr})
                : format(value, 'p');
            }
            return lng === 'fr'
              ? format(value, 'dd MMM yyyy', { locale: fr })
              : format(value, 'dd MMM yyyy')
          default:
            return value;
        }
      }
    },
  });

export default i18next;