

export const fadingKeyframes = '@keyframes fading { 0% { opacity: 0.2; } 50% { opacity: 0.4; } 100% { opacity: 0.2; } }';

export function getMergedStyles(...params: any[]): any {
    if (!params || !params.length) {
        return null;
    }
    
    const styles = [...params];
    const firstStyle = styles.shift();

    const firstStyleDefinition = getStyleDefinition(firstStyle);
    if (!firstStyleDefinition) {
        return null;
    }

    for (const style of styles) {
        const styleDefinition = getStyleDefinition(style);
        if (styleDefinition) {
            firstStyle[0][1] = `${firstStyle[0][1]} ${styleDefinition}`
        }
    }

    firstStyle[0][1] = `${firstStyle[0][1]} ${fadingKeyframes}`

    return firstStyle;
}

function getStyleDefinition(style: any[]): string {
    return style && style.length > 0 && style[0].length > 1 
        ? style[0][1]
        : null;
}